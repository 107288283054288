<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.payment_reports") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
          w-reset
        "
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Отчёты платежей"
          name="Отчёты платежей.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'payment'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <div
      class="col-7 align-items-center align-self-center text-left p-0 mb-2 m-d-flex w-reset"
    >
      <span class="align-items-center align-self-center pr-2"
        ><el-tag
          >{{ $t("message.total_amount") }}:
          {{ Math.round(prices.total_price) | formatMoney }}
        </el-tag></span
      >
      <span class="align-items-center align-self-center pr-2"
        ><el-tag type="success"
          >{{ $t("message.paid") }}:
          {{ Math.round(prices.paid_price) | formatMoney }}
        </el-tag></span
      >
      <span class="align-items-center align-self-center pr-2"
        ><el-tag type="danger"
          >{{ $t("message.refund") }}:
          {{ Math.round(prices.refund_price) | formatMoney }}
        </el-tag></span
      >
      <span class="align-items-center align-self-center pr-2"
        ><el-tag type="warning"
          >{{ $t("message.not_paid") }}:
          {{ Math.round(prices.no_paid) | formatMoney }}
        </el-tag></span
      >
    </div>
    <div class="type-t-color">
     <span><i class="color_t1"></i> {{ $t("message.not_paid") }}</span>
      <span><i class="color_t2"></i> {{ $t("message.paid") }}</span>
      <span><i class="color_t3"></i> {{ $t("message.not_full") }}</span>
      <span><i class="color_t4"></i> {{ $t("message.lend") }} </span>
      <span><i class="color_t5"></i> {{ $t("message.without_amount") }}</span>
      <span><i class="color_t6"></i> {{ $t("message.refund") }}</span>
      
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.total_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.paid_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.payment_type"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
         
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
             <el-input
              clearable
              size="mini"
              class="d-block"
              v-model="filterForm.patient_id"
              :placeholder="$t('message.patient')"
            ></el-input>          
          </th>
          <th v-if="columns.total_price.show">
            <!-- <el-input size="mini" v-model="filterForm.total_price" :placeholder="columns.total_price.title" clearable> -->
          </th>
          <th v-if="columns.paid_price.show">
            <!-- <el-input size="mini" v-model="filterForm.paid_price" :placeholder="columns.paid_price.title" clearable> -->
          </th>
          <th v-if="columns.payment_type.show">
            <crm-payment-types v-model="filterForm.payment_type_id" :payment_type_id="filterForm.payment_type_id" size="mini" :plc="columns.payment_type.title">
            </crm-payment-types>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            >
            </el-date-picker>
          </th>
          
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="patient in list"
          :key="patient.id"
          :style="colorPicker(patient)"
          id="tr_pointer"
          v-on:dblclick="show(patient.id)"
        >
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.patient_id.show">
            {{ patient.patient ? patient.patient.surname : "" }}
            {{ patient.patient ? patient.patient.first_name : "" }}
          </td>
          <td v-if="columns.total_price.show">
            {{ patient.total_price | formatMoney }}
          </td>
          <td v-if="columns.paid_price.show">
            {{
              (typeof patient.payment[0] != "undefined"
                ? patient.payment[0].paid_price
                : 0) | formatMoney
            }}
          </td>
          <td>{{ paymentType(patient) }}</td>
          <td v-if="columns.created_at.show">
            {{ patient.created_at }}
          </td>
          
        </tr>
      </transition-group>
    </table>

    <el-drawer
      :visible.sync="drawerUpdate"
      size="85%" :wrapperClosable="false"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer>

    <el-drawer
      :visible.sync="tabAddPayment"
      size="85%" :wrapperClosable="false"
      :drawer="tabAddPayment"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
       
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import CrmPaymentTypes from '../../components/selects/crm-paymentTypes.vue';
export default {
  mixins: [list],
  name: "PatientServiceList",
  components: { CrmPaymentTypes },
  data() {
    return {
      loadTable: false,
      tabAddPayment: false,
      drawerUpdate: false,
      drawerFind: false,
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "paymentReport/list",
      prices: "paymentReport/prices",
      doctor_list: "users/list",
      columns: "paymentReport/columns",
      pagination: "paymentReport/pagination",
      filter: "paymentReport/filter",
      sort: "paymentReport/sort",
      socials: "socials/list",
      patients: "patients/inventory",
      rooms: "rooms/list",
      conditions: "conditions/list",
      partner_doctors: "partnerDoctor/list",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
  },
  async mounted() {
    this.fetchData();
    this.controlExcelData();

    if (this.patients && this.patients.length === 0) await this.loadPatients();

    if (this.rooms && this.rooms.length === 0) await this.loadRooms();

    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions();

    if (this.partner_doctors && this.partner_doctors.length === 0)
      await this.loadPartnerDoctors({ column: 'name', order: 'asc'});
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "paymentReport/index",
      doctorList: "users/index",
      updateSort: "paymentReport/updateSort",
      updateFilter: "paymentReport/updateFilter",
      updateColumn: "paymentReport/updateColumn",
      updatePagination: "paymentReport/updatePagination",
      empty: "paymentReport/empty",
      refreshData: "paymentReport/refreshData",
      loadPatients: "patients/inventory",
      loadRooms: "rooms/index",
      loadConditions: "conditions/index",
      loadPartnerDoctors: "partnerDoctor/index",
    }),
    async fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
      const data = { doctor: true };
      await this.doctorList(data)
        .then((res) => {})
        .catch((err) => {});
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
          this.fetchData();
        })
        .catch((err) => {});
    },
   
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "doctor_id":
                this.excel_fields[column.title] = "doctor.name";
                break;
             case "patient_id":
                this.excel_fields[column.title] = {
                  field: "patient",
                  callback: (value) => {
                    return value.surname + " " + value.first_name;
                  },
                };
                break;

              case "condition_id":
                this.excel_fields[column.title] = "condition.name";
                break;
              case "paid_price":
                this.excel_fields[column.title] = "payment.0.paid_price";
                break;

              case "payment_type":
                this.excel_fields[column.title] = {
                  field: "payment.0.transactions",
                  callback: (payment_type) => {
                    return this.paymentTypeExcel(payment_type);
                  },
                };
                break;

              default:
                this.excel_fields[column.title] = column.column;
            }
          }
        }
      }
    },
    emptyModel() {
      this.empty();
    },
    paymentTypeExcel(transactions) {
      let array = [];
      if (transactions.length) {
        transactions.forEach((value, index) => {
          if (!array.includes(value.payment_type.name)) {
            array.push(value.payment_type.name);
          }
        });
      }
      return array.toString();
    },
    paymentType(patient) {
      let array = [];
      if (patient.payment.length) {
        patient.payment[0].transactions.forEach((value, index) => {
          if (!array.includes(value.payment_type.name)) {
            array.push(value.payment_type.name);
          }
        });
      }
      return array.toString();
    },
    colorPicker(patient) {
      if (
        patient.refund == true &&
        patient.payment[0].paid_price != patient.payment[0].total_price
      ) {
        return "background: rgb(249 176 182);";
      }
      if (patient.payment_status != "unpaid") {
        if (patient.payment_status == "paid")
          return "background: rgb(171 255 168 / 62%);";
        if (patient.payment_status == "debt")
          return "background: rgb(209, 194, 255);";
        if (patient.payment_status == "free_charge")
          return "background: rgb(133, 168, 255);";
      }
      if (patient.payment.length && patient.payment[0].paid_price != 0) {
        return "background:rgb(249 255 137 / 52%);";
      }
    },
  },
};
</script>
<style >
#tr_pointer {
  cursor: pointer;
}
.type-t-color {
  display: flex;
  align-items: center;
}
.type-t-color span {
  display: flex;
  align-items: center;
  margin: 0px 3px;
  font-size: 14px;
}
.type-t-color span i {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 3px;
  margin-right: 4px;
}

.color_t1 {
  background-color: #fff;
}
.color_t2 {
  background-color: rgb(171 255 168 / 62%);
}
.color_t3 {
  background-color: rgb(249 255 137 / 52%);
}
.color_t4 {
  background-color: rgb(209, 194, 255);
}
.color_t5 {
  background-color: rgb(133, 168, 255);
}
.color_t6 {
  background-color: rgb(249 176 182);
}
</style>

